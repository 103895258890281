import React, { useState } from 'react';
import { FormControl, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import RadioGroup from '../components/RadioGroup'

function QuestionState({ errors, id, index, setValue, watch, NextPage }: any) {
  const [age, setAge] = useState('0');
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
    setValue('state', event.target.value);
  };

  const states = [
    { value: '0', label: 'Escolha o seu estado...'},
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' }
  ];

  const optionsRadio = [
    { value: 'capital', label: 'A capital' },
    { value: 'interior', label: 'Do Interior' },
    { value: 'litoral', label: 'Do litoral' }
  ];

  return (
    <div className='card' key={index} data-scroll={id}>
      <FormControl fullWidth>
      <h1 className='mt25'>Em qual estado você mora?</h1>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={'0'}
          value={age}
          placeholder='Escolha o seu estado...'
          onChange={handleChange}
        >
          {/* <option value={'0'} hidden>Escolha o seu estado...</option> */}
          {states.map((state, index_state) => (
            <MenuItem disabled={state.value === '0'} key={index_state} value={state.value}>{state.label}</MenuItem>
          ))}
        </Select>

        <h1 className='mt25'>Dentro do seu estado, a sua cidade é...</h1>
        <RadioGroup options={optionsRadio} value={watch('locationType')} onChange={(e: any) => setValue('locationType', (e.target as HTMLInputElement).value)} />
      </FormControl>
      <footer className='nextPage'>
        <NextPage />
      </footer>
    </div>
  );
}

export default QuestionState;
