import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../../components/RadioGroup'

function questionFrequencyDrink({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: '1x_semana', label: 'Até uma vez por semana' },
    { value: '2x_3x_semana', label: 'Duas a três vezes por semana' },
    { value: '4x_5x_semana', label: 'Quatro a cinco vezes por semana' },
    { value: '6x_mais_semana', label: 'Seis ou mais vezes por semana' },
  ];

  return (
    <section>
      {progress >= id + 1 &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Com que frequência bebe cerveja semanalmente?</h1>
            <RadioGroup options={options} value={watch('frequencyDrink')} onChange={(e: any) => setValue('frequencyDrink', (e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default questionFrequencyDrink;
