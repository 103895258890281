import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../../components/RadioGroup'

function QuestionHowPayBeers({ index, id, progress, setValue, watch, NextPage }: any) {


  const options = [
    { value: '5_7', label: 'De cinco a sete reais' },
    { value: '7_10', label: 'De R$ 7,01 a R$ 10,00' },
    { value: '10_13', label: 'De R$ 10,01 a R$ 13,00' },
    { value: '13_15', label: 'De R$ 13,01 a R$ 15,00' },
    { value: '15_mais', label: 'R$ 15,01 ou mais' },
  ];

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "comuns" &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Quanto você constuma pagar por uma garrafa de 600ml no bar?</h1>
            <RadioGroup options={options} value={watch('howPayBeers')} onChange={(e: any) => setValue('howPayBeers', (e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionHowPayBeers;
