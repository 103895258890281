import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../../components/RadioGroup'

function QuestionFavPackBeer({ index, id, progress, setValue, watch, NextPage }: any) {


  const options = [
    { value: 'longneck', label: 'Longneck' },
    { value: 'lata', label: 'Lata' },
    { value: 'latao', label: 'Latão' },
    { value: '600ml', label: 'Garrafa 600ml' },
    { value: 'litrao', label: 'Litrão' },
  ].sort((a, b) => {
    const nameA = a.label.toUpperCase();
    const nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "comuns" &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Qual tipo de embalagem você prefere para sua cerveja?</h1>
            <RadioGroup options={options} value={watch('favPackBeer')} onChange={(e: any) => setValue('favPackBeer', (e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionFavPackBeer;
