import React from 'react';
import { FormControl } from '@mui/material';
import CheckBox from '../../components/CheckBox';

function QuestionNoEventBeer({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'localizacao', label: 'Localização' },
    { value: 'valor_cervejas', label: 'Preço das cervejas' },
    { value: 'valor_ingresso', label: 'Preço dos ingressos' },
    { value: 'nao_gosta', label: 'Não gosto' },
    { value: 'sem_companhia', label: 'Não tenho companhia' },
  ];
  
  const handleCheck = (optionId: string) => {
    const arrSelect = watch('noEventBeer') as [string];

    if (arrSelect.includes(optionId)) {
      // Desmarcar a opção se já estiver selecionada
      setValue('noEventBeer', arrSelect.filter(id => id !== optionId));
    } else {
      setValue('noEventBeer', [...arrSelect, optionId]);
    }
  };

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "especiais_comuns" && watch('beerEvent') === 'nao' &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Por que você nunca foi a um evento cervejeiro?</h1>
            <CheckBox options={options} selectedOptions={watch('noEventBeer')} onChange={(e: any) => handleCheck((e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionNoEventBeer;
