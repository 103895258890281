import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../components/RadioGroup'

function QuestionJobType({ index, id, progress, setValue, watch, NextPage }: any) {


  const options = [
    { value: 'fundamental', label: 'Fundamental' },
    { value: 'medio', label: 'Médio (segundo grau)' },
    { value: 'superior_incompleto', label: 'Superior incompleto' },
    { value: 'superior_completo', label: 'Superior completo' },
    { value: 'mba_pos', label: 'MBA / Pós-graduação' },
    { value: 'mestrado', label: 'Mestrado' },
    { value: 'doutorado', label: 'Doutorado' },
    { value: 'pos_doutorado', label: 'Pós-doutorado' },
    { value: 'nao_respondeu', label: 'Prefiro não dizer' },
  ];

  return (
    <section>
      {progress >= id + 1 &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Qual a sua escolaridade?</h1>
            <RadioGroup options={options} value={watch('education')} onChange={(e: any) => setValue('education', (e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionJobType;
