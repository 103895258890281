import React from 'react';
import { FormControl } from '@mui/material';
import CheckBox from '../../components/CheckBox';

function QuestionBrewerPodcasts({ index, id, progress, setValue, watch, NextPage }: any) {


  const options = [
    { value: 'nenhum', label: 'Nenhum desses' },
    { value: 'beerCast', label: 'BeerCast' },
    { value: 'brassaria_brasilia', label: 'Brassaria Brasília' },
    { value: 'brassagem_forte', label: 'Brassagem Forte' },
    { value: 'hora_do_gole', label: 'Hora do Gole' },
    { value: 'la_biere_hopCast', label: 'La Bière HopCast' },
    { value: 'surra_de_lupulo', label: 'Surra de Lúpulo' },
  ].sort((a, b) => {
    const nameA = a.label.toUpperCase();
    const nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const handleCheck = (optionId: string) => {
    const arrSelect = watch('brewerPodcasts') as [string];

    if (arrSelect.includes(optionId)) {
      // Desmarcar a opção se já estiver selecionada
      setValue('brewerPodcasts', arrSelect.filter(id => id !== optionId));
    } else if (optionId === 'nenhum') {
      // Marcar a opção "não" desmarca qualquer outra
      setValue('brewerPodcasts', [optionId]);
    } else if (arrSelect.includes('nenhum')) {
      // Se tiver marcado a opção "não" marcar qualquer outra a desmarca
      setValue('brewerPodcasts', [optionId]);
    } else if (arrSelect.length < 3) {
      // Marcar a opção se ainda não foram selecionadas 3 opções
      setValue('brewerPodcasts', [...arrSelect, optionId]);
    }
  };

  return (
    <section>
      {progress >= id + 1 && watch('listenPodcast') === 'sim' && watch('beerType') === "especiais_comuns" &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Da lista abaixo, marque até 3 podcasts que você mais costuma ouvir.</h1>
            <CheckBox options={options} selectedOptions={watch('brewerPodcasts')} onChange={(e: any) => handleCheck((e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionBrewerPodcasts;
