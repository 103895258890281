import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../components/RadioGroup'

function QuestionRacial({ index, id, progress, setValue, watch, NextPage }: any) {


  const options = [
    { value: 'asiatica', label: 'Asiático(a)' },
    { value: 'branca', label: 'Branco(a)' },
    { value: 'hispanica', label: 'Hispânico(a)' },
    { value: 'negra', label: 'Negro(a)' },
    { value: 'indigena', label: 'Povos originários (indígenas)' },
    { value: 'nao_respondeu', label: 'Prefiro não dizer' },
  ];

  return (
    <section>
      {progress >= id + 1 &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Qual a sua etnia?</h1>
            <RadioGroup options={options} value={watch('racial')} onChange={(e: any) => setValue('racial', (e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionRacial;
