import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import styles from './index.module.scss';

type Props = {
  options: Options[];
  value: number;
  onChange: (e: any) => any
};

type Options = {
  value: number;
  label?: string;
};

export default function RowRadioButtonsGroup({ value, options, onChange }: Props) {
  return (
    <RadioGroup row className={styles.radioGroup} >
      {options.map((option, index) => (        
        <div key={index} className={`${option.value === value ? styles.check : ""}  ${styles.container}`}>          
          {/* {index === 0 && <span className='labelRange'>{option.label}</span>} */}
          <Radio
            value={option.value}
            checked={value === option.value}                        
            onChange={onChange}
          />
          {/* {index === 4 && <span className='labelRange'>{option.label}</span>} */}
        </div>
      ))}
    </RadioGroup>
  );
}