import React from 'react';
import { FormControl } from '@mui/material';
import CheckBox from '../../components/CheckBox';

function QuestionWhereBuyBeer({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'app_delivery', label: 'Aplicativos de entrega' },
    { value: 'bares_lojas', label: 'Bares e lojas especializadas' },
    { value: 'site_cervejaria', label: 'Direto no site da cervejaria ou de sites especializados' },
    { value: 'fabricacao_propria', label: 'Fabricação própria' },
    { value: 'brewpubs_fabrica', label: 'Indo a brewpubs ou direto na fábrica' },
    { value: 'supermercado', label: 'Supermercados' },
    { value: 'whatsapp', label: 'Whatsapp' },
  ];
  
  const handleCheck = (optionId: string) => {
    const arrSelect = watch('whereBuyBeer') as [string];

    if (arrSelect.includes(optionId)) {
      // Desmarcar a opção se já estiver selecionada
      setValue('whereBuyBeer', arrSelect.filter(id => id !== optionId));
    } else if (arrSelect.length < 3) {
      // Marcar a opção se ainda não foram selecionadas 3 opções
      setValue('whereBuyBeer', [...arrSelect, optionId]);
    }
  };

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "especiais_comuns"  &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Marque os 3 lugares nos quais você MAIS costuma adquirir as suas cervejas.</h1>
            <CheckBox options={options} selectedOptions={watch('whereBuyBeer')} onChange={(e: any) => handleCheck((e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionWhereBuyBeer;
