/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Alert, Button, Snackbar } from '@mui/material';
import { CheckIcon } from '@heroicons/react/24/solid';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import { useForm } from "react-hook-form"
import { HiArrowRight } from 'react-icons/hi';
import axios from 'axios';
import Header from './components/header';
import footerImg from './images/footer.jpg'
import selo1 from './images/SeloPesquisa_1.png'
import selo2 from './images/SeloPesquisa_2.png'
import selo3 from './images/SeloPesquisa_3.png'
import selo4 from './images/SeloPesquisa_4.png'
import LoadingBar from './components/loadingBar'
import Email from './components/Email'
import { FirstResearchs, SecondResearchs, ThirdResearchs, FourthResearchs } from './components/EmailsToSend'
import listEmail from './docs/emails.json'
import ReactPixel from 'react-facebook-pixel';
import QuestionName from './questions/questionName';
import QuestionEmail from './questions/questionEmail';

import QuestionState from './questions/questionState';
import QuestionYearsOld from './questions/questionYearsOld';
import QuestionJobType from './questions/questionJobType';
import QuestionsEducation from './questions/questionsEducation';
import QuestionRacial from './questions/questionRacial';
import QuestionGender from './questions/questionGender';
import QuestionSexual from './questions/questionSexual';
import QuestionBeerType from './questions/questionBeerType';
import QuestionBeersBrands from './questions/questionBeersBrands';

// NORMAL BEERS
import QuestionOtherDrinks from './questions/NormalBeers/questionOtherDrinks';

// SPECIAL BEERS
import QuestionBrewerType from './questions/SpecialsBeers/questionBrewerType';
import QuestionCongress from './questions/SpecialsBeers/questionCongress';
import QuestionBeerConsum from './questions/SpecialsBeers/questionBeerConsum';
import QuestionFirstBeer from './questions/SpecialsBeers/questionFirstBeer';
import QuestionFavBeersStyle from './questions/SpecialsBeers/questionFavBeersStyle';
import QuestionBrewerHome from './questions/SpecialsBeers/questionBrewerHome';
import QuestionStopBrewery from './questions/SpecialsBeers/questionStopBrewery';
import QuestionBrewerAssociation from './questions/SpecialsBeers/questionBrewerAssociation';
import QuestionBrewerConfraternity from './questions/SpecialsBeers/questionBrewerConfraternity';
import QuestionStopConfraternity from './questions/SpecialsBeers/questionStopConfraternity';
import QuestionBeerEvent from './questions/SpecialsBeers/questionBeerEvent';
import QuestionNoEventBeer from './questions/SpecialsBeers/questionNoEventBeer';
import QuestionYesEventBeer from './questions/SpecialsBeers/questionYesEventBeer';
import QuestionFavEventBeerType from './questions/SpecialsBeers/questionFavEventBeerType';
import QuestionResearchBeers from './questions/SpecialsBeers/questionResearchBeers';
import QuestionHowResearchBeers from './questions/SpecialsBeers/questionHowResearchBeers';

// PODCAST
import QuestionListenPodcast from './questions/Podcasts/questionListenPodcast';
import QuestionBrewerPodcasts from './questions/Podcasts/questionBrewerPodcasts';

// COSTUMES
import QuestionWhoYouDrinks from './questions/Costumes/questionWhoYouDrinks';

// SPECIAL BEERS
import QuestionMusicStyle from './questions/SpecialsBeers/questionMusicStyle';
import QuestionWhereBuyBeer from './questions/SpecialsBeers/questionWhereBuyBeer';
import QuestionMotivateToBuy from './questions/SpecialsBeers/questionMotivateToBuy';

// COSTUMES
import QuestionFrequencyDrink from './questions/Costumes/questionFrequencyDrink';

// SPECIAL BEERS
import QuestionBeerAddiction from './questions/SpecialsBeers/questionBeerAddiction';
import QuestionBeerSpend from './questions/SpecialsBeers/questionBeerSpend';
import QuestionOtherBeerItens from './questions/SpecialsBeers/questionOtherBeerItens';
import QuestionWhatOtherItens from './questions/SpecialsBeers/questionWhatOtherItens';
import QuestionSignClub from './questions/SpecialsBeers/questionSignClub';
import QuestionClubAttraction from './questions/SpecialsBeers/questionClubAttraction';
import QuestionHowManyClubs from './questions/SpecialsBeers/questionHowManyClubs';
import QuestionNoClubSign from './questions/SpecialsBeers/questionNoClubSign';
import QuestionOtherClubs from './questions/SpecialsBeers/questionOtherClubs';
import QuestionBeerCourse from './questions/SpecialsBeers/questionBeerCourse';
import QuestionWhichCourses from './questions/SpecialsBeers/questionWhichCourses';
// import QuestionBeerEAD from './questions/SpecialsBeers/questionBeerEAD';
import QuestionHowEADBeer from './questions/SpecialsBeers/questionHowEADBeer';

// NORMAL BEERS
import QuestionMomentConsumBeer from './questions/NormalBeers/questionMomentConsumBeer';
import QuestionFavPackBeer from './questions/NormalBeers/questionFavPackBeer';
import QuestionHowPayBeers from './questions/NormalBeers/questionHowPayBeers';
import QuestionHowSpendBeers from './questions/NormalBeers/questionHowSpendBeers';
import QuestionLightBeer from './questions/NormalBeers/questionLightBeer';
import QuestionFriendsSpecialBeer from './questions/NormalBeers/questionFriendsSpecialBeer';
import QuestionWhatsStopsSpecialBeer from './questions/NormalBeers/questionWhatsStopsSpecialBeer';
import QuestionZeroBeer from './questions/ZeroBeers/questionZeroBeer';

type Inputs = {
  state: string
  locationType: string

  yearsOld: string
  jobType: string
  education: string
  racial: string
  gender: string
  sex: string
  beerType: string
  beersBrands: string[]

  // NORMAL BEERS
  otherDrinks: string[]

  // SPECIAL BEERS
  brewerType: string
  congress: string
  beerConsum: {
    birthday: string
    bbq: string
    happyHour: string
    dinner: string
    especialDate: string
    romanticDinner: string
    night: string
    show: string
    sports: string
  }
  firstBeer: string
  favBeersStyle: string[]

  // ZERO BEER
  zeroBeer: string
  lightBeer: string

  brewerHome: string
  stopBrewery: string
  brewerAssociation: string
  brewerConfraternity: string
  stopConfraternity: string
  beerEvent: string
  noEventBeer: string[]
  yesEventBeer: string[]
  favEventBeerType: string
  researchBeers: string
  howResearchBeers: string[]

  // PODCAST
  listenPodcast: string
  brewerPodcasts: string[]

  // COSTUMES
  whoYouDrinks: string[]

  // SPECIAL BEERS
  musicStyle: string[]
  whereBuyBeer: string[]
  motivateToBuy: string[]

  // COSTUMES
  frequencyDrink: string

  // SPECIAL BEERS
  beerAddiction: string
  beerSpend: string
  otherBeerItens: string
  whatOtherItens: string[]
  signClub: string
  clubAttraction: string
  howManyClub: string
  noClubSign: string
  otherClubs: string[]
  beerCourse: string
  whichCourses: string[]
  beerEad: string,
  howEadBeer: string[]

  // NORMAL BEERS
  momentConsumBeer: {
    sports: string,
    series: string,
    home: string,
    happyHour: string,
    events: string,
  }
  favPackBeer: string
  howPayBeers: string
  howSpendBeers: string
  friendsSpecialBeer: string
  whatsStopsSpecialBeer: string

  // FINAL  
  name: string
  email: string
  hopPills: boolean
  prussiaBeer: boolean
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#01988C',
    },
  },
});

function App() {
  const faviconTag = document.getElementById("faviconTag") as HTMLLinkElement;
  const isDark = window.matchMedia("(prefers-color-scheme: dark)");

  const changeFavicon = () => {
    if (isDark.matches) faviconTag.href = "./favicon_dark.png";
    else faviconTag.href = "./favicon_light.png";
  };

  useEffect(() => {
    changeFavicon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ReactPixel.init('588899909615713');
  }, []);

  const [progress, setProgress] = useState(0);
  const [porcentagem, setPorcentagem] = useState(0);
  const [finish, setFinish] = useState(true);
  const [sendEmail, setSendEmail] = useState(false);
  const [totalResearchs, setTotalResearchs] = useState(0);
  const [idSave, setIdSave] = useState<number | null>(null);
  const [alertOpen, setAlertOpen] = useState({
    open: false, msg: ""
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      locationType: "",
      state: "",
      yearsOld: "",
      jobType: "",
      education: "",
      racial: "",
      gender: "",
      sex: "",
      beerType: "",
      beersBrands: [],

      // NORMAL BEERS
      otherDrinks: [],

      // SPECIAL BEERS
      brewerType: "",
      congress: '',
      beerConsum: {
        birthday: '',
        bbq: '',
        happyHour: '',
        especialDate: '',
        dinner: '',
        romanticDinner: '',
        night: '',
        show: '',
        sports: ''
      },
      firstBeer: "",
      favBeersStyle: [],
      brewerHome: "",
      stopBrewery: "",
      brewerAssociation: '',
      brewerConfraternity: "",
      stopConfraternity: '',
      beerEvent: '',
      noEventBeer: [],
      yesEventBeer: [],
      favEventBeerType: '',
      researchBeers: '',
      howResearchBeers: [],

      // PODCAST
      listenPodcast: '',
      brewerPodcasts: [],

      // COSTUMES
      whoYouDrinks: [],

      // SPECIAL BEERS
      musicStyle: [],
      whereBuyBeer: [],
      motivateToBuy: [],

      // COSTUMES
      frequencyDrink: "",

      // SPECIAL BEERS
      beerAddiction: "",
      beerSpend: "",
      otherBeerItens: '',
      whatOtherItens: [],
      signClub: '',
      clubAttraction: "",
      howManyClub: "",
      noClubSign: "",
      otherClubs: [],
      beerCourse: '',
      whichCourses: [],
      beerEad: '',
      howEadBeer: [],

      // NORMAL BEERS
      momentConsumBeer: {
        sports: '',
        series: '',
        home: '',
        happyHour: '',
        events: '',
      },
      favPackBeer: "",
      howPayBeers: "",
      howSpendBeers: "",
      zeroBeer: '',
      lightBeer: '',
      friendsSpecialBeer: '',
      whatsStopsSpecialBeer: '',

      // FINAL
      name: '',
      email: '',
      hopPills: false,
      prussiaBeer: false,
    }
  });

  const questions = [
    { id: 0, key: 0, page: QuestionState },
    { id: 1, key: 1, page: QuestionYearsOld },
    { id: 2, key: 2, page: QuestionRacial },
    { id: 3, key: 3, page: QuestionGender },
    { id: 4, key: 4, page: QuestionSexual },
    { id: 5, key: 5, page: QuestionsEducation },
    { id: 6, key: 6, page: QuestionJobType },
    { id: 7, key: 7, page: QuestionBrewerType },
    { id: 8, key: 8, page: QuestionBeersBrands },
    { id: 9, key: 9, page: QuestionBeerType },

    // NORMAL BEERS
    { id: 10, key: 10, page: QuestionOtherDrinks },

    // SPECIAL BEERS
    { id: 10, key: 11, page: QuestionBeerConsum },
    { id: 11, key: 12, page: QuestionFirstBeer },
    { id: 12, key: 13, page: QuestionFavBeersStyle },

    // ZERO BEER
    { id: 13, key: 14, page: QuestionLightBeer },
    { id: 14, key: 15, page: QuestionZeroBeer },

    { id: 15, key: 16, page: QuestionWhoYouDrinks },
    { id: 16, key: 17, page: QuestionMusicStyle },

    //OS DOIS FLUXOS
    { id: 17, key: 18, page: QuestionFrequencyDrink },

    { id: 18, key: 19, page: QuestionBeerAddiction },
    { id: 19, key: 20, page: QuestionWhereBuyBeer },
    { id: 20, key: 21, page: QuestionMotivateToBuy },
    { id: 21, key: 22, page: QuestionBeerSpend },
    { id: 22, key: 23, page: QuestionOtherBeerItens },
    { id: 23, key: 24, page: QuestionWhatOtherItens },
    { id: 24, key: 25, page: QuestionSignClub },
    { id: 25, key: 26, page: QuestionNoClubSign },
    { id: 25, key: 27, page: QuestionClubAttraction },
    { id: 26, key: 28, page: QuestionHowManyClubs },
    { id: 27, key: 29, page: QuestionOtherClubs },

    { id: 28, key: 30, page: QuestionBrewerHome },
    { id: 29, key: 31, page: QuestionStopBrewery },
    { id: 29, key: 32, page: QuestionBrewerAssociation },
    { id: 30, key: 33, page: QuestionBrewerConfraternity },
    { id: 31, key: 34, page: QuestionStopConfraternity },
    { id: 32, key: 35, page: QuestionBeerEvent },
    { id: 33, key: 36, page: QuestionNoEventBeer },
    { id: 33, key: 37, page: QuestionYesEventBeer },
    { id: 34, key: 38, page: QuestionFavEventBeerType },

    { id: 35, key: 39, page: QuestionResearchBeers },
    { id: 36, key: 40, page: QuestionHowResearchBeers },
    // PODCASTS
    { id: 37, key: 41, page: QuestionListenPodcast },
    { id: 38, key: 42, page: QuestionBrewerPodcasts },
    { id: 39, key: 43, page: QuestionBeerCourse },
    { id: 40, key: 44, page: QuestionWhichCourses },
    { id: 41, key: 45, page: QuestionHowEADBeer },
    { id: 42, key: 46, page: QuestionCongress },

    // NORMAL BEERS
    { id: 19, key: 47, page: QuestionMomentConsumBeer },
    { id: 23, key: 48, page: QuestionFavPackBeer },
    { id: 27, key: 49, page: QuestionHowPayBeers },
    { id: 31, key: 50, page: QuestionHowSpendBeers },
    { id: 35, key: 51, page: QuestionFriendsSpecialBeer },
    { id: 39, key: 52, page: QuestionWhatsStopsSpecialBeer },

    { id: 43, key: 53, page: QuestionName }
  ];

  const handleProgress = () => {
    const total = 44;
    let count = 0;
    const emailPattern = /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/;
    if (emailPattern.test(watch('email'))) clearErrors('email');    

    if (watch('state')) count++
    if (watch('locationType')) count++

    if (watch('yearsOld')) count++
    if (watch('jobType')) count++
    if (watch('education')) count++
    if (watch('racial')) count++
    if (watch('gender')) count++
    if (watch('sex')) count++
    if (watch('beerType')) count++
    if (watch('beersBrands').length > 0) count++

    // NORMAL BEERS
    if (watch('otherDrinks').length > 0 && watch('beerType') === 'comuns') count = count + 3;

    // SPECIAL BEERS
    if (watch('brewerType')) count++
    const beerConsum = (watch('beerConsum.bbq') && watch('beerConsum.birthday') && watch('beerConsum.happyHour') && watch('beerConsum.especialDate') && watch('beerConsum.dinner') && watch('beerConsum.romanticDinner') && watch('beerConsum.night') && watch('beerConsum.show') && watch('beerConsum.sports'));
    if (beerConsum) count++
    if (watch('firstBeer')) count++
    if (watch('favBeersStyle').length > 0) count++

    // ZERO BEERS
    if (watch('lightBeer')) count++
    if (watch('zeroBeer')) count++
    if (watch('zeroBeer') && watch('beerType') === 'comuns') count = count + 2;

    if (watch('brewerHome')) count++
    if (watch('brewerHome') === "nunca_fiz") count++
    if (watch('stopBrewery') && watch('brewerHome') === "ja_fiz") count++
    if (watch('brewerAssociation') && watch('brewerHome') === "atualmente") count++
    if (watch('brewerConfraternity')) count++
    if (watch('brewerConfraternity') === 'nunca_fiz' || watch('brewerConfraternity') === 'atualmente') count++
    if (watch('stopConfraternity')) count++
    if (watch('beerEvent')) count++
    if (watch('beerEvent') === 'nao' && watch('noEventBeer').length > 0) count = count + 2;
    if (watch('beerEvent') === 'sim' && watch('yesEventBeer').length > 0) count++
    if (watch('beerEvent') === 'sim' && watch('yesEventBeer').length === 1) count++
    if (watch('favEventBeerType')) count++
    if (watch('researchBeers')) count++
    if (watch('researchBeers') === 'sim' && watch('howResearchBeers').length > 0) count++
    if (watch('researchBeers') === 'nao') count++

    // PODCAST
    if (watch('listenPodcast')) count++
    if (watch('listenPodcast') === 'sim' && watch('brewerPodcasts').length > 0) count++
    if (watch('listenPodcast') === 'nao') count++

    // COSTUMES
    if (watch('whoYouDrinks').length > 0) count++
    if (watch('beerType') === 'comuns' && (watch('whoYouDrinks').length > 0)) count = count + 3;

    // SPECIAL BEERS
    if (watch('musicStyle').length > 0) count++
    if (watch('whereBuyBeer').length > 0) count++
    if (watch('motivateToBuy').length > 0) count++

    // COSTUMES
    if (watch('frequencyDrink')) count++
    if (watch('frequencyDrink') && watch('beerType') === 'comuns') count++

    // SPECIAL BEERS
    if (watch('beerAddiction')) count++
    if (watch('beerSpend')) count++
    if (watch('otherBeerItens')) count++
    if (watch('otherBeerItens') === 'nao') count++
    if (watch('whatOtherItens').length > 0) count++
    if (watch('signClub')) count++
    if (watch('clubAttraction')) count++
    if (watch('howManyClub')) count++
    if (watch('noClubSign')) count = count + 2;
    if (watch('otherClubs').length > 0) count++
    if (watch('beerCourse')) count++;
    if (watch('beerCourse') === 'nao') count++;
    if (watch('beerCourse') === 'sim' && watch('whichCourses').length > 0) count++;
    if (watch('howEadBeer').length > 0) count++;
    if (watch('congress')) count++

    // NORMAL BEERS
    const momentConsumBeer = (watch('momentConsumBeer.sports') && watch('momentConsumBeer.series') && watch('momentConsumBeer.home') && watch('momentConsumBeer.happyHour') && watch('momentConsumBeer.events'))
    if (momentConsumBeer) count = count + 4;
    if (watch('favPackBeer')) count = count + 4;
    if (watch('howPayBeers')) count = count + 4;
    if (watch('howSpendBeers')) count = count + 4;
    if (watch('friendsSpecialBeer')) count = count + 4;
    if (watch('whatsStopsSpecialBeer')) count = count + 4;

    // if (watch('name')) count++;
    // if (watch('email') && !errors.email) count++;

    setPorcentagem((count * 100) / total);
    setProgress(count);
    if (count >= 2) {
      if (count === 10) {
        if (watch('beersBrands').includes('nenhuma_dessas')) return handleScroll()
        else if (watch('beersBrands').length === 3) return handleScroll()
      }
      else if (count === 11 && watch('beerType') === 'especiais_comuns') {
        if (watch('beerConsum.birthday')) return ''
        else return handleScroll();
      }
      else if (count === 14 && watch('beerType') === 'comuns') {
        if (watch('otherDrinks').length === 3) return handleScroll()
        else if (watch('otherDrinks').includes("so_cerveja")) return handleScroll()
        else return ''
      }
      else if (count === 14 && watch('beerType') === 'especiais_comuns') {
        if (watch('favBeersStyle').length === 3) return handleScroll()
      }
      else if (count === 17 && watch('beerType') === 'especiais_comuns') {
        if (watch('whoYouDrinks').length === 2) return handleScroll()
      }
      else if (count === 18 && watch('beerType') === 'especiais_comuns') {
        if (watch('musicStyle').length === 3) return handleScroll()
      }
      else if (count === 21 && watch('beerType') === 'especiais_comuns') {
        if (watch('whereBuyBeer').length === 3) return handleScroll()
      }
      else if (count === 22 && watch('beerType') === 'especiais_comuns') {
        if (watch('motivateToBuy').length === 3) return handleScroll()
      }
      else if (count === 25 && watch('beerType') === 'especiais_comuns') {
        if (watch('whatOtherItens').length === 3) return handleScroll()
      }
      else if (count === 20 && watch('beerType') === 'comuns') {
        if (watch('momentConsumBeer.sports')) return ''
        // else if (watch('otherDrinks').length === 3) return handleScroll()
        // else if (watch('otherDrinks').includes("so_cerveja")) return handleScroll()
        else return handleScroll()
      }
      else if (count === 29 && watch('beerType') === 'especiais_comuns') {
        if (watch('otherClubs').includes('nao')) return handleScroll()
        else if (watch('otherClubs').length === 3) return handleScroll()
      }
      else if (count === 35) {
        if (watch('yesEventBeer').length === 3) return handleScroll()
      }
      else if (count === 36 && watch('beerType') === 'especiais_comuns') {
        if (watch('favEventBeerType')) return handleScroll()
      }
      else if (count === 38 && watch('beerType') === 'especiais_comuns') {
        if (watch('howResearchBeers').length === 3 || watch('researchBeers') === 'nao') handleScroll()
      }
      else if (count === 40 && watch('beerType') === 'especiais_comuns') {
        if (watch('brewerPodcasts').length === 3 || watch('listenPodcast') === 'nao') return handleScroll()
        else if (watch('brewerPodcasts').includes('nenhum')) return handleScroll()
      }
      else if (count === 42 && watch('beerCourse') === 'sim') {
        if (watch('whichCourses').length === 3) return handleScroll()
      }
      else if (count === 43 && watch('beerType') === 'especiais_comuns') {
        if (watch('howEadBeer').includes('nao_faria')) return handleScroll()
        else if (watch('howEadBeer').length === 3) return handleScroll()
      }
      else return handleScroll()
    }
  };

  useEffect(() => {
    if(finish || porcentagem >= 100) { handleScroll() }
    else  handleProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()])

  const saveApi = async (e: any) => {
    // console.log("Salvando... ", e);
    
    setTotalResearchs(Number(listEmail.find((user) => user.Email.toLowerCase() === watch('email').toLowerCase())?.Total));
    const data = {
      ...e,
      consumo_em_aniversario: e.beerConsum.birthday || 0,
      consumo_em_churrasco: e.beerConsum.birthday || 0,
      consumo_em_happyhour: e.beerConsum.birthday || 0,
      consumo_data_especial: e.beerConsum.birthday || 0,
      consumo_jantar_casa: e.beerConsum.birthday || 0,
      consumo_jantar_romantico: e.beerConsum.birthday || 0,
      consumo_em_noitada: e.beerConsum.birthday || 0,
      consumo_em_show: e.beerConsum.birthday || 0,
      consumo_evento_esportivo: e.beerConsum.birthday || 0,

      // momentConsumBeer: e.momentConsumBeer.join(";"),
      consumo_cerveja_esportes: e.momentConsumBeer.sports || 0,
      consumo_cerveja_series: e.momentConsumBeer.series || 0,
      consumo_cerveja_casa: e.momentConsumBeer.home || 0,
      consumo_cerveja_happy_hour: e.momentConsumBeer.happyHour || 0,
      consumo_cerveja_eventos: e.momentConsumBeer.events || 0,

      beersBrands: e.beersBrands.join(";"),
      brewerPodcasts: e.brewerPodcasts.join(";"),
      favBeersStyle: e.favBeersStyle.join(";"),
      howEadBeer: e.howEadBeer.join(";"),
      howResearchBeers: e.howResearchBeers.join(";"),
      motivateToBuy: e.motivateToBuy.join(";"),
      musicStyle: e.musicStyle.join(";"),
      noEventBeer: e.noEventBeer.join(";"),
      otherClubs: e.otherClubs.join(";"),
      otherDrinks: e.otherDrinks.join(";"),
      // stopBrewery: e.stopBrewery.join(";"),
      whatOtherItens: e.whatOtherItens.join(";"),
      whereBuyBeer: e.whereBuyBeer.join(";"),
      whichCourses: e.whichCourses.join(";"),
      whoYouDrinks: e.whoYouDrinks.join(";"),
      yesEventBeer: e.yesEventBeer.join(";"),
    }
    setFinish(true);

    try {
      const saveResponse = await axios.post('https://surradelupulo.com.br/wp-json/api/v1/retrato-savedata/', data) as any;
      setIdSave(saveResponse.data.inserted_id);

    } catch (error) {
      console.error(error);
    }
    if (watch('email')) {
      SendPageCopy();
    }
    
    ReactPixel.track('CompleteRegistration');
  };

  const SendPageCopy = () => {
    try {
      setSendEmail(true);
      const pageContent = ReactDOMServer.renderToString(returnSelo().email);
      const email = watch('email');

      axios.post('https://surradelupulo.com.br/wp-json/send-email/v1/send', { email, pageContent });

      setAlertOpen({ open: true, msg: "Agradecemos a sua participação, o e-mail foi enviado para " + watch('email') });

    } catch (error) {
      console.error('Error sending the page copy:', error);
    }
    console.log(totalResearchs);
  };

  const updateEmail = async () => {
    if (watch('email') && idSave) {
      const data = {
        id: idSave,
        nome: watch('name'),
        email: watch('email'),
        hopPills: watch('hopPills'),
        prussiaBeer: watch('prussiaBeer')
      }
      await axios.post('https://surradelupulo.com.br/wp-json/api/v1/retrato-update/', data);
      SendPageCopy();
    }
  };

  const handleScroll = () => {
    const $header = document.getElementById('header');
    const $section = document.querySelector(`[data-scroll="${progress - 1}"]`);
    if (finish && $header) return $header.scrollIntoView({ behavior: 'smooth', block: 'start' });
    if ($section) $section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    else return ""
  };

  function NextPage() {
    return (
      <Button className='btn-next' size='small' onClick={handleScroll} variant='text' endIcon={<HiArrowRight className='green' />}>Próximo</Button>
    )
  };

  const returnSelo = () => {
    switch (Number(listEmail.find((user) => user.Email.toLowerCase() === watch('email').toLowerCase())?.Total)) {
      case 1:
        return { selo: selo2, texto: textSelo2, email: <SecondResearchs /> }
      case 2:
        return { selo: selo3, texto: textSelo3, email: <ThirdResearchs /> }
      case 3:
        return { selo: selo4, texto: textSelo4, email: <FourthResearchs /> }
      default:
        return { selo: selo1, texto: textSelo1, email: <FirstResearchs /> }
    }
  };

  const textSelo1 = (
    <p className='text-selo'>Inclusive, analisando nossos dados, vimos essa é a sua <b>primeira vez conosco.</b> Então de presente te damos esse selo para que você compartilhe nas suas redes sociais.</p>
  );
  const textSelo2 = (
    <p className='text-selo'>Inclusive, analisando nossos dados, vimos essa é a sua <b>segunda vez conosco</b>, é ótimo te ver aqui de novo. Então de presente te damos esse selo para que você compartilhe nas suas redes sociais.</p>
  );
  const textSelo3 = (
    <p className='text-selo'>Inclusive, analisando nossos dados, vimos essa é a sua <b>terceira vez conosco</b>, isso já é um compromisso sério entre nós. Então de presente te damos esse selo para que você compartilhe nas suas redes sociais.</p>
  );
  const textSelo4 = (
    <p className='text-selo'>Inclusive, analisando nossos dados, vimos que você <b>gabaritou e participou de todas as edições.</b> Então de presente te damos esse selo para que você compartilhe nas suas redes sociais.</p>
  );

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <div className="App">
          <div className='page-container'>
            <Header />
            {finish
              ?
              <div className='content'>
                <div className='email'>
                  <Email totalResearchs={totalResearchs} setAlertOpen={setAlertOpen} />
                </div>
                {!sendEmail && idSave &&
                  <QuestionEmail
                    errors={errors}
                    register={register}
                    watch={watch}
                    setValue={setValue}
                    progress={progress}
                    updateEmail={updateEmail}
                  />}
                <div className='finishFooterSpace'> </div>
              </div>
              :
              // CONTENT AFTER 01 AGOSTO
              <div className='content'>
                <div>
                  <h1 className='mt0'>Retrato dos consumidores e consumidoras de cervejas 2023</h1>
                  <p className='p1'>Cerveja é uma das paixões nacionais e queremos entender quem está conversando conosco e toda a diversidade existente no mundo dos bebedores e bebedoras de cervejas.</p>
                </div>

                {questions.map((question, index) => (
                  <question.page
                    key={question.key}
                    id={question.id}
                    index={index}
                    errors={errors}
                    register={register}
                    watch={watch}
                    setValue={setValue}
                    progress={progress}
                    NextPage={NextPage}
                  />
                ))
                }

                <div className='footerSpace'> </div>
              </div>
            }
          </div>

          <div className='footer'>
            {!finish &&
              <div className='loadingBar'>
                <LoadingBar value={porcentagem} />
                <div className='btn-container'>
                  <Button
                    onClick={handleSubmit(data => saveApi(data))}
                    variant="contained"
                    fullWidth
                    disabled={porcentagem < 95.5}
                    endIcon={<CheckIcon className='checkIcon' />}
                  >
                    Enviar
                  </Button>
                </div>
              </div>
            }
            <div>
              <div className='linksPtrocinadores-container'>
                <a href='https://www.ambev.com.br/' className='linkPatrocinador1' target='_blank' rel="noreferrer"></a>
                <a href='https://www.academiadacerveja.com/' className='linkPatrocinador2' target='_blank' rel="noreferrer"></a>
                <a href='https://loja.prussiabier.com.br/' className='linkPatrocinador3' target='_blank' rel="noreferrer"></a>
                <a href='https://www.sindicerv.com.br/' className='linkPatrocinador4' target='_blank' rel="noreferrer"></a>
                <a href='https://www.irispay.com.br/' className='linkPatrocinador5' target='_blank' rel="noreferrer"></a>                
              </div>
              <img src={footerImg} alt="Surra de Lúpulo Rodapé" />
            </div>
          </div>

          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={alertOpen.open} autoHideDuration={2500} onClose={() => setAlertOpen({ open: false, msg: '' })}>
            <Alert onClose={() => setAlertOpen({ open: false, msg: '' })} severity="success" sx={{ width: '100%' }}>
              {alertOpen.msg}
            </Alert>
          </Snackbar>
        </div>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
