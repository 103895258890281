import React from 'react';
import { FormControl } from '@mui/material';
import RadioRange from '../../components/RadioRange'

function QuestionBeerConsum({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 1, label: 'Populares' },
    { value: 2, label: '' },
    { value: 3, label: '' },
    { value: 4, label: '' },
    { value: 5, label: 'Artesanais' },
  ];

  const fields = [
    { label: 'Em um aniversário', reg: 'beerConsum.birthday' },
    { label: 'Em um churrasco', reg: 'beerConsum.bbq' },
    { label: 'Em um happy hour', reg: 'beerConsum.happyHour' },
    { label: 'Em uma data especial', reg: 'beerConsum.especialDate' },
    { label: 'Em um jantar em casa', reg: 'beerConsum.dinner' },
    { label: 'Em um jantar romântico', reg: 'beerConsum.romanticDinner' },
    { label: 'Em uma noitada', reg: 'beerConsum.night' },
    { label: 'Em um show', reg: 'beerConsum.show' },
    { label: 'Em um evento esportivo', reg: 'beerConsum.sports' },
  ];

  const handleScroll = (field: string, value: string, key: number) => {    
    setValue(field, value);
    const $section = document.querySelector(`[data-radiorange-scroll="${key+1}"]`);
    console.log($section);
    
    if (!$section) return console.log("section não encontrada");
    $section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "especiais_comuns" &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <div>
              <h1 className='mt0 mb0'>Entre as comuns e artesanais</h1>
              <p style={{ fontSize: '16px', color: '#4E4E4E' }}>
                Nas perguntas que seguem abaixo, queremos saber como funciona a sua decisão de consumo em cada ocasião citada. Fique atento que as duas opções da esquerda demonstram que você prefere em determinada ocasião as cervejas comuns. Já as duas opções da direita indicam que você prefere as cervejas artesanais. E a opção do meio demonstra que você não tem preferência por uma ou outra.
              </p>
              <div className='rangeItens-Container'>
                <div className='rangeItens range1'>Somente populares</div>
                <div className='rangeItens range2'>Prefere populares</div>
                <div className='rangeItens range3'>Indiferente</div>
                <div className='rangeItens range4'>Prefere artesanais</div>
                <div className='rangeItens range5'>Somente artesanais</div>
              </div>
            </div>

            <div className='radioRange'>
              {fields.map((field, key) => (
                <div className='mb15' data-radiorange-scroll={key} key={key}>
                  <div className='radioRange-label'>
                    <p>{field.label}</p><div className='line'></div>
                  </div>
                  <RadioRange options={options} value={Number(watch(field.reg))} onChange={(e: any) => handleScroll(field.reg, (e.target as HTMLInputElement).value, key)} />
                </div>
              ))}
            </div>

          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionBeerConsum;
