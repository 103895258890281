import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from './index.module.scss';

type Props = {
  options: Options[];
  value: string;
  onChange: (e: any) => any
};

type Options = {
  value: string;
  label: string;
};

export default function RowRadioButtonsGroup({ value, options, onChange }: Props) {

  return (
    <RadioGroup >
      {options.map((option, index) => (
        <div key={index} className={option.value === value ? styles.check + " " + styles.container : styles.unCheck + " " + styles.container}>
          <FormControlLabel
            value={option.value}
            checked={value === option.value}
            control={<Radio />}
            label={option.label}
            onChange={onChange}
          />
        </div>
      ))}
    </RadioGroup>
  );
}