import React, { useState } from 'react';
import InputText from '../components/InputText/inputText';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

function QuestionName({ errors, index, id, progress, register, watch, setValue }: any) {
  const [data, setData] = useState({
    name: '',
    email: '',
  });

  const handleCheck = (type: string, value: boolean) => {
    setTimeout(() => {
      setValue(type, value);
    }, 100)
  };

  return (
    <section>
      {progress >= id + 1 &&
        <div className='card' key={index} data-scroll={id} hidden={progress >= 2}>
          <h1 className='mt0'>Quer receber o resultado dessa pesquisa em primeira mão?</h1>
          <p className='mt0'>Preencha os campos abaixo caso queira receber o resultado dessa pesquisa e marque as caixas para passar a receber a Hop-Pills e ofertas e comunicações da Prussia Bier.</p>
          <InputText
            label={'Qual é o seu nome?'}
            placeholder=''
            sucess={!!data.name}
            formData={data}
            setValue={setValue}
            name='name'
            setData={setData}
          />

          <div className='mt17'>
            <InputText
              label={'Qual é o seu email?'}
              placeholder='Ex.: nome@servidor.com'
              sucess={!!data.email}
              formData={data}
              setValue={setValue}
              name='email'
              setData={setData}
              error={!!errors.email}
              msgError={errors?.email}
            />
            <input hidden {...register('email', {
              // required: false,
            })} />

            <FormGroup className='mt17 checkboxes'>
              <FormControlLabel onChange={(e) => handleCheck('hopPills', (e.target as HTMLInputElement).checked)} control={<Checkbox />} label="Aceito receber a Hop-Pills, newsletter do Surra de Lúpulo" />
              <FormControlLabel onChange={(e) => handleCheck('prussiaBeer', (e.target as HTMLInputElement).checked)} control={<Checkbox />} label="Aceito receber ofertas e comunicações da Prussia Bier" />
            </FormGroup>
            <p className=''>
              Este formulário tem todos os direitos reservados ao Surra de Lúpulo Produções LTDA
            </p>
          </div>


        </div>}
    </section>
  );
}

export default QuestionName;
