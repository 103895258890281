import React from 'react';
import { FormControl } from '@mui/material';
import CheckBox from '../../components/CheckBox';

function QuestionOtherDrinks({ index, id, progress, setValue, watch }: any) {

  const options = [
    { value: 'so_cerveja', label: 'Não bebo nada além da cervejinha' },
    { value: 'cachaca', label: 'Cachaça' },
    { value: 'gin', label: 'Gin' },
    { value: 'hard_seltzer', label: 'Hard seltzer' },
    { value: 'licor', label: 'Licor' },
    { value: 'sidra', label: 'Sidra' },
    { value: 'tequila', label: 'Tequila' },
    { value: 'vinho', label: 'Vinho' },
    { value: 'vodka', label: 'Vodka' },
    { value: 'whisky', label: 'Whisky' },    
  ];
  
  const handleCheck = (optionId: string) => {
    const arrSelect = watch('otherDrinks') as [string];

    if (arrSelect.includes(optionId)) {
      // Desmarcar a opção se já estiver selecionada
      setValue('otherDrinks', arrSelect.filter(id => id !== optionId));
    } else if (optionId === 'so_cerveja') {
      // Marcar a opção "não" desmarca qualquer outra
      setValue('otherDrinks', [optionId]);
    } else if (arrSelect.includes('so_cerveja')) {
      // Se tiver marcado a opção "não" marcar qualquer outra a desmarca
      setValue('otherDrinks', [optionId]);
    } else if (arrSelect.length < 3) {
      // Marcar a opção se ainda não foram selecionadas 3 opções
      setValue('otherDrinks', [...arrSelect, optionId]);
    }
  };

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "comuns" &&
        <div className='card' key={id} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Além das cervejas populares, você consome outro tipo de bebida alcoólica? (marcar até 3 opções)</h1>
            <CheckBox options={options} selectedOptions={watch('otherDrinks')} onChange={(e: any) => handleCheck((e.target as HTMLInputElement).value)} />
          </FormControl>
        </div>}
    </section>
  );
}

export default QuestionOtherDrinks;
