import React from 'react';
import { FormControl } from '@mui/material';
import CheckBox from '../../components/CheckBox';

function QuestionFavBeersStyle({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'american_lager', label: 'American Lager' },
    { value: 'apa', label: 'APA' },
    { value: 'bock', label: 'Bock' },
    { value: 'blond_ale', label: 'Blond Ale' },
    { value: 'dubbel', label: 'Dubbel' },
    { value: 'dunkel', label: 'Dunkel' },
    { value: 'english_ipa', label: 'English IPA' },
    { value: 'gose', label: 'Gose' },
    { value: 'ipa', label: 'IPA' },
    { value: 'neipa', label: 'NEIPA (Hazy IPA e Juicy IPA)' },
    { value: 'ordinary_biter', label: 'Ordinary Bitter' },
    { value: 'pilsen', label: 'Pilsen' },
    { value: 'quadruppel', label: 'Quadruppel' },
    { value: 'red_ale', label: 'Red Ale' },
    { value: 'saison', label: 'Saison' },
    { value: 'sour', label: 'Sour' },
    { value: 'stout_porter', label: 'Stout ou Porter' },
    { value: 'russian_imperial_stout', label: 'Russian Imperial Stout (RIS)' },
    { value: 'tripel', label: 'Tripel' },
    { value: 'weissbier', label: 'Weissbier' },
    { value: 'witbier', label: 'Witbier' },
  ].sort((a, b) => {
    const nameA = a.label.toUpperCase();
    const nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  
  const handleCheck = (optionId: string) => {
    const arrSelect = watch('favBeersStyle') as [string];

    if (arrSelect.includes(optionId)) {
      // Desmarcar a opção se já estiver selecionada
      setValue('favBeersStyle', arrSelect.filter(id => id !== optionId));
    } else if (arrSelect.length < 3) {
      // Marcar a opção se ainda não foram selecionadas 3 opções
      setValue('favBeersStyle', [...arrSelect, optionId]);
    }
  };

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "especiais_comuns"  &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Quais estilos bebe com mais frequência hoje? Marque até 3.</h1>
            <CheckBox options={options} selectedOptions={watch('favBeersStyle')} onChange={(e: any) => handleCheck((e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionFavBeersStyle;
