import React from 'react';
import { FormControl } from '@mui/material';
import CheckBox from '../../components/CheckBox';

function QuestionMotivateToBuy({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'dica_internet', label: 'Busco dicas na internet' },
    { value: 'custo_beneficio', label: 'Custo x benefício' },
    { value: 'origem', label: 'Escola / Origem (alemã, inglesa, belga, americana)' },
    { value: 'estilo', label: 'Estilo da cerveja' },
    { value: 'harmonizacao', label: 'Harmonização com pratos' },
    { value: 'lancamentos', label: 'Lançamentos' },
    { value: 'marcas_conhecidas', label: 'Marcas conhecidas' },
    { value: 'marcas_desconhecidas', label: 'Marcas desconhecidas (desbravando)' },
    { value: 'preco', label: 'Preço' },
    { value: 'indicacao_amigos', label: 'Recomendações de amigos' },
    { value: 'indicacao_especialistas', label: 'Recomendações de especialistas' },
    { value: 'rotulo_visual', label: 'Rótulo (visual)' },
  ];
  
  const handleCheck = (optionId: string) => {
    const arrSelect = watch('motivateToBuy') as [string];

    if (arrSelect.includes(optionId)) {
      // Desmarcar a opção se já estiver selecionada
      setValue('motivateToBuy', arrSelect.filter(id => id !== optionId));
    } else if (arrSelect.length < 3) {
      // Marcar a opção se ainda não foram selecionadas 3 opções
      setValue('motivateToBuy', [...arrSelect, optionId]);
    }
  };

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "especiais_comuns"  &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>O que você leva em consideração na hora de comprar? (máximo 3 opções)</h1>
            <CheckBox options={options} selectedOptions={watch('motivateToBuy')} onChange={(e: any) => handleCheck((e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionMotivateToBuy;
