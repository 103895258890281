const body = {
  flexDirection: 'column' as "column",
  background: '#F5F5F5',
  margin: 0,
  fontFamily: 'Lato, sans-serif',
  display: 'flex',
  alignItems: 'center',
  maxWidth: '700px'
};

const h1 = {
  padding: '0px 15px',
  fontSize: '20px',
  maxWidth: '670px',
  textAlign: 'left' as 'left',
}

const p = {
  color: '#4E4E4E',
  maxWidth: '301px',
  fontSize: '16px',
  fontWeight: '500',
  textAlign: 'left' as 'left',
};

const contentFinish = {
  display: 'table-cell',
  verticalAlign: 'middle',
  textAlign: 'center' as 'center',
};

const cupom = {
  width: '221px',
  height: '41px',
  color: 'white',
  fontSize: '14px',
  borderRadius: '10px',
  background: '#68360A',
  fontWeight: '700',
  border: 'none',
  marginTop: '30px',
  // display: 'flex',
  // flexDirection: 'column' as "column",
  // textAlign: 'center' as 'center',
  // justifyContent: 'center' as 'center',
};

const buttonLink = {
  marginTop: '20px',
  maxWidth: '561px',
  width: '80%',
  height: '53px',
  color: 'white',
  fontSize: '16px',
  borderRadius: '10px',
  background: '#B05707',
  fontWeight: '700',
  border: 'none',
  cursor: 'pointer'
  // display: 'flex',
  // textAlign: 'center' as 'center',
  // flexDirection: 'column' as 'column',
  // justifyContent: 'center' as 'center',
};

const a = {
  textDecoration: 'none'
};

const img = {
  width: '271px',
  height: '271px',
  marginRight: '20px'
};

const emailWrapper = {
  width: '100%',
  maxWidth: '700px',
  margin: '0 auto',
  fontFamily: 'Lato, sans-serif',
  backgroundColor: '#F5F5F5',
};

const footer = {
  marginTop: '20px'
}

const linkParceiro = 'https://loja.prussiabier.com.br/?utm_source=retrato-surra&utm_medium=retrato-surra&utm_campaign=retrato-surra';
const footerImg = 'https://retrato.surradelupulo.com.br/images_email/footer_v5.jpg';
const linkHeaderImg = 'https://retrato.surradelupulo.com.br/images_email/header.png';
const codCupom = 'RET23COD200430';

function FirstResearchs() {
  const handleCopyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = 'RET23COD200430';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    alert('Texto copiado para o Clipboard: RET23COD200430');
  };

  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <link rel="icon" href="https://retrato.surradelupulo.com.br/favicon_light.png" id="faviconTag" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="Surra de Lupulo®" content="Pesquisa realizada por Surra de Lupulo®" />
        <link rel="apple-touch-icon" href="https://retrato.surradelupulo.com.br/favicon_light.png" />
        <title>Pesquisa - Surra de Lúpulo</title>
      </head>

      <body style={body}>
        <table role="presentation" cellPadding={0} cellSpacing={0} style={{ width: '100%', height: '100%', border: '0' }}>
          <tbody>
            <tr>
              <td align="center" valign="top" style={{ height: '100%' }}>
                <div style={emailWrapper}>
                  <div>
                    <header>
                      <img src={linkHeaderImg} alt="Logo" />
                    </header>
                    <div>
                      <h1 style={h1}>Agradecemos você pelo tempo e informações preciosas para a realização da pesquisa Retrato dos Consumidores de
                        Cervejas 2023. Sem você esse projeto não seria possível!</h1>
                    </div>
                    <div style={contentFinish}>
                      <div style={{ padding: '0px', display: 'flex' }}>
                        <div>
                          <img style={img} src='https://retrato.surradelupulo.com.br/images_email/SeloPesquisa_1.png' alt="Selo" />
                          <div>
                            <p style={p}>Inclusive, analisando nossos dados, vimos essa é a sua <b>primeira vez conosco.</b> Então de presente te damos esse selo para que você compartilhe nas suas redes sociais.</p>
                            <p style={p}>E como você nos ajudou muito até aqui, agora é a nossa vez de retribuir. A nossa
                              patrocinadora <b>Prussia Bier</b> vai ajudar você a celebrar os bons momentos da vida com esse cupom exclusivo!
                              Clique neste link e aproveite a sua recompensa.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button style={cupom} onClick={() => handleCopyToClipboard()}>
                      CUPOM: {codCupom}
                    </button>
                    <br />
                    <div>
                      <a href={linkParceiro} style={a}>
                        <button style={buttonLink}>
                          Aproveite o seu cupom agora!
                        </button>
                      </a>
                    </div>

                    <br />
                    <div style={footer} >
                      <img src={footerImg} alt="Surra de Lúpulo Rodapé" />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </body>

    </html>
  );
};

function SecondResearchs() {
  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <link rel="icon" href="https://retrato.surradelupulo.com.br/favicon_light.png" id="faviconTag" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="Surra de Lupulo®" content="Pesquisa realizada por Surra de Lupulo®" />
        <link rel="apple-touch-icon" href="https://retrato.surradelupulo.com.br/favicon_light.png" />
        <title>Pesquisa - Surra de Lúpulo</title>
      </head>

      <body style={body}>
        <table role="presentation" cellPadding={0} cellSpacing={0} style={{ width: '100%', height: '100%', border: '0' }}>
          <tbody>
            <tr>
              <td align="center" valign="top" style={{ height: '100%' }}>
                <div style={emailWrapper}>
                  <div>
                    <header>
                      <img src={linkHeaderImg} alt="Logo" />
                    </header>
                    <div>
                      <h1 style={h1}>Agradecemos você pelo tempo e informações preciosas para a realização da pesquisa Retrato dos Consumidores de
                        Cervejas 2023. Sem você esse projeto não seria possível!</h1>
                    </div>
                    <div style={contentFinish}>
                      <div style={{ padding: '0px', display: 'flex' }}>
                        <img style={img} src='https://retrato.surradelupulo.com.br/images_email/SeloPesquisa_2.png' alt="Selo" />
                        <div>
                          <p style={p}>Inclusive, analisando nossos dados, vimos essa é a sua <b>segunda vez conosco</b>, é ótimo te ver aqui de novo. Então de presente te damos esse selo para que você compartilhe nas suas redes sociais.</p>
                          <p style={p}>E como você nos ajudou muito até aqui, agora é a nossa vez de retribuir. A nossa
                            patrocinadora <b>Prussia Bier</b> vai ajudar você a celebrar os bons momentos da vida com esse cupom exclusivo!
                            Clique neste link e aproveite a sua recompensa.</p>
                        </div>
                      </div>
                    </div>
                    <button style={cupom}>
                      CUPOM: {codCupom}
                    </button>
                    <br />
                    <div>
                      <a href={linkParceiro} style={a}>
                        <button style={buttonLink}>
                          Aproveite o seu cupom agora!
                        </button>
                      </a>
                    </div>

                    <br />
                    <div style={footer} >
                      <img src={footerImg} alt="Surra de Lúpulo Rodapé" />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </body>

    </html>
  );
};

function ThirdResearchs() {
  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <link rel="icon" href="https://retrato.surradelupulo.com.br/favicon_light.png" id="faviconTag" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="Surra de Lupulo®" content="Pesquisa realizada por Surra de Lupulo®" />
        <link rel="apple-touch-icon" href="https://retrato.surradelupulo.com.br/favicon_light.png" />
        <title>Pesquisa - Surra de Lúpulo</title>
      </head>

      <body style={body}>
        <table role="presentation" cellPadding={0} cellSpacing={0} style={{ width: '100%', height: '100%', border: '0' }}>
          <tbody>
            <tr>
              <td align="center" valign="top" style={{ height: '100%' }}>
                <div style={emailWrapper}>
                  <div>
                    <header>
                      <img src={linkHeaderImg} alt="Logo" />
                    </header>
                    <div>
                      <h1 style={h1}>Agradecemos você pelo tempo e informações preciosas para a realização da pesquisa Retrato dos Consumidores de
                        Cervejas 2023. Sem você esse projeto não seria possível!</h1>
                    </div>
                    <div style={contentFinish}>
                      <div style={{ padding: '0px', display: 'flex' }}>
                        <img style={img} src='https://retrato.surradelupulo.com.br/images_email/SeloPesquisa_3.png' alt="Selo" />
                        <div>
                          <p style={p}>Inclusive, analisando nossos dados, vimos essa é a sua <b>terceira vez conosco</b>, isso já é um compromisso sério entre nós. Então de presente te damos esse selo para que você compartilhe nas suas redes sociais.</p>
                          <p style={p}>E como você nos ajudou muito até aqui, agora é a nossa vez de retribuir. A nossa
                            patrocinadora <b>Prussia Bier</b> vai ajudar você a celebrar os bons momentos da vida com esse cupom exclusivo!
                            Clique neste link e aproveite a sua recompensa.</p>
                        </div>
                      </div>
                    </div>
                    <button style={cupom}>
                      CUPOM: {codCupom}
                    </button>
                    <br />
                    <div>
                      <a href={linkParceiro} style={a}>
                        <button style={buttonLink}>
                          Aproveite o seu cupom agora!
                        </button>
                      </a>
                    </div>

                    <br />
                    <div style={footer} >
                      <img src={footerImg} alt="Surra de Lúpulo Rodapé" />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </body>

    </html>
  );
};

function FourthResearchs() {
  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <link rel="icon" href="https://retrato.surradelupulo.com.br/favicon_light.png" id="faviconTag" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="Surra de Lupulo®" content="Pesquisa realizada por Surra de Lupulo®" />
        <link rel="apple-touch-icon" href="https://retrato.surradelupulo.com.br/favicon_light.png" />
        <title>Pesquisa - Surra de Lúpulo</title>
      </head>

      <body style={body}>
        <table role="presentation" cellPadding={0} cellSpacing={0} style={{ width: '100%', height: '100%', border: '0' }}>
          <tbody>
            <tr>
              <td align="center" valign="top" style={{ height: '100%' }}>
                <div style={emailWrapper}>
                  <div>
                    <header>
                      <img src={linkHeaderImg} alt="Logo" />
                    </header>
                    <div>
                      <h1 style={h1}>Agradecemos você pelo tempo e informações preciosas para a realização da pesquisa Retrato dos Consumidores de
                        Cervejas 2023. Sem você esse projeto não seria possível!</h1>
                    </div>
                    <div style={contentFinish}>
                      <div style={{ padding: '0px', display: 'flex' }}>
                        <img style={img} src='https://retrato.surradelupulo.com.br/images_email/SeloPesquisa_4.png' alt="Selo" />
                        <div>
                          <p style={p}>Inclusive, analisando nossos dados, vimos que você <b>gabaritou e participou de todas as edições.</b> Então de presente te damos esse selo para que você compartilhe nas suas redes sociais.</p>
                          <p style={p}>E como você nos ajudou muito até aqui, agora é a nossa vez de retribuir. A nossa
                            patrocinadora <b>Prussia Bier</b> vai ajudar você a celebrar os bons momentos da vida com esse cupom exclusivo!
                            Clique neste link e aproveite a sua recompensa.</p>
                        </div>
                      </div>
                    </div>
                    <button style={cupom}>
                      CUPOM: {codCupom}
                    </button>
                    <br />
                    <div>
                      <a href={linkParceiro} style={a}>
                        <button style={buttonLink}>
                          Aproveite o seu cupom agora!
                        </button>
                      </a>
                    </div>

                    <br />
                    <div style={footer} >
                      <img src={footerImg} alt="Surra de Lúpulo Rodapé" />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </body>

    </html>
  );
}


export { FirstResearchs, SecondResearchs, ThirdResearchs, FourthResearchs };
