import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../components/RadioGroup'

function QuestionJobType({ index, id, progress, setValue, watch, NextPage }: any) {


  const options = [
    { value: 'autonomo_freelancer', label: 'Autônomo(a) / Freelancer' },
    { value: 'aposentado_pensionista', label: 'Aposentado(a) / Pensionista' },
    { value: 'CLT', label: 'Contratado(a) CLT' },
    { value: 'contrato_juridico', label: 'Contratado(a) Pessoa Juridica' },
    { value: 'empresario', label: 'Empresário(a)' },
    { value: 'servidor', label: 'Funcionário(a) Público(a)' },
    { value: 'sem_renda', label: 'Sem renda' },
    { value: 'nao_respondeu', label: 'Prefiro não dizer' },
  ];

  return (
    <section>
      {progress >= id + 1 &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Qual é a sua fonte de renda?</h1>
            <RadioGroup options={options} value={watch('jobType')} onChange={(e: any) => setValue('jobType', (e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionJobType;
