import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../../components/RadioGroup'

function QuestionBeerAddiction({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'baixo', label: 'Baixo' },
    { value: 'moderado', label: 'Moderado' },
    { value: 'alto', label: 'Alto' },
    { value: 'problematico', label: 'Problemático' },
  ];

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "especiais_comuns" &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Como você considera o seu consumo?</h1>
            <RadioGroup options={options} value={watch('beerAddiction')} onChange={(e: any) => setValue('beerAddiction', (e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionBeerAddiction;
