import React from 'react';
import { FormControl } from '@mui/material';
import CheckBox from '../../components/CheckBox';

function QuestionHowResearchBeers({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'blogs_sites', label: 'Sites ou Blogs especializados' },
    { value: 'facebook', label: 'Facebook' },
    { value: 'grupos_whatsapp', label: 'Grupos de Whatsapp' },
    { value: 'indicacoes_amigos', label: 'Indicações de amigos' },
    { value: 'instagram', label: 'Instagram' },
    { value: 'linkedin', label: 'Linkedin' },
    { value: 'livros_revistas', label: 'Livros e revistas' },
    { value: 'newsletters', label: 'Newsletters' },
    { value: 'podcasts', label: 'Podcasts' },    
    { value: 'tiktok', label: 'Tiktok' },
    { value: 'Twitter', label: 'Twitter' },
    { value: 'untappd', label: 'Untappd' },
    { value: 'youtube', label: 'YouTube' },
    { value: 'outros', label: 'Outros' },
  ].sort((a, b) => {
    const nameA = a.label.toUpperCase();
    const nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  
  const handleCheck = (optionId: string) => {
    const arrSelect = watch('howResearchBeers') as [string];

    if (arrSelect.includes(optionId)) {
      // Desmarcar a opção se já estiver selecionada
      setValue('howResearchBeers', arrSelect.filter(id => id !== optionId));
    } else if (arrSelect.length < 3)  {
      // Marcar a opção se ainda não foram selecionadas 3 opções
      setValue('howResearchBeers', [...arrSelect, optionId]);
    }
  };

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "especiais_comuns" && watch('researchBeers') === 'sim' &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Por quais canais você MAIS costuma buscar informações? (marcar até 3 opções)</h1>
            <CheckBox options={options} selectedOptions={watch('howResearchBeers')} onChange={(e: any) => handleCheck((e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionHowResearchBeers;
