import React from 'react';
import { FormControl } from '@mui/material';
import CheckBox from '../../components/CheckBox';

function QuestionWhoYouDrinks({ index, id, progress, setValue, watch, NextPage }: any) {


  const options = [
    { value: 'amigos', label: 'Amigos e Amigas' },
    { value: 'colegas_trabalho', label: 'Colegas de trabalho' },
    { value: 'conjugue', label: 'Companheiro(a)' },
    { value: 'familiares', label: 'Familiares' },
    { value: 'sozinho', label: 'Sozinho(a)' },
  ];
  
  const handleCheck = (optionId: string) => {
    const arrSelect = watch('whoYouDrinks') as [string];

    if (arrSelect.includes(optionId)) {
      // Desmarcar a opção se já estiver selecionada
      setValue('whoYouDrinks', arrSelect.filter(id => id !== optionId));
    } else if (arrSelect.length < 2) {
      // Marcar a opção se ainda não foram selecionadas 3 opções
      setValue('whoYouDrinks', [...arrSelect, optionId]);
    }
  };

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "especiais_comuns"  &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Com quem você costuma beber MAIS frequentemente suas cervejas? (Marque até 2 opções)</h1>
            <CheckBox options={options} selectedOptions={watch('whoYouDrinks')} onChange={(e: any) => handleCheck((e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionWhoYouDrinks;
