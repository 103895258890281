import React from 'react';
import { FormControl } from '@mui/material';
import CheckBox from '../../components/CheckBox';

function QuestionOtherClubs({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'nao', label: 'Não' },
    { value: 'cafe', label: 'Sim, clube de café' },
    { value: 'vinho', label: 'Sim, clube de vinho' },
    { value: 'whiskey', label: 'Sim, clube de Whiskey' },
  ];
  
  const handleCheck = (optionId: string) => {
    const arrSelect = watch('otherClubs') as [string];

    if (arrSelect.includes(optionId)) {
      // Desmarcar a opção se já estiver selecionada
      setValue('otherClubs', arrSelect.filter(id => id !== optionId));
    } else if (optionId === 'nao') {
      // Marcar a opção não desmarca qualquer outra
      setValue('otherClubs', [optionId]);
    } else if (arrSelect.includes('nao')) {
      // Se tiver marcado a opção "não" marcar qualquer outra a desmarca
      setValue('otherClubs', [optionId]);
    } else {      
      setValue('otherClubs', [...arrSelect, optionId]);
    }
  };

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "especiais_comuns"  &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>E clubes de outras bebidas, você assina?</h1>
            <CheckBox options={options} selectedOptions={watch('otherClubs')} onChange={(e: any) => handleCheck((e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionOtherClubs;
