import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../components/RadioGroup'

function QuestionYeasOld({ index, id, progress, setValue, watch, NextPage }: any) {


  const options = [
    { value: '18_25', label: 'entre 18 e 25 anos' },
    { value: '26_35', label: 'entre 26 e 35 anos' },
    { value: '36_45', label: 'entre 36 e 45 anos' },
    { value: '46_55', label: 'entre 46 e 55 anos' },
    { value: '56_65', label: 'entre 56 e 65 anos' },
    { value: '65+', label: 'mais de 65 anos' },
  ];

  return (
    <section>
      {progress >= id + 1 &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Quantos anos você tem?</h1>
            <RadioGroup options={options} value={watch('yearsOld')} onChange={(e: any) => setValue('yearsOld', (e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionYeasOld;
