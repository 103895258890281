import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../components/RadioGroup'

function QuestionGender({ index, id, progress, setValue, watch, NextPage }: any) {


  const options = [
    { value: 'homem', label: 'Homem' },
    { value: 'mulher', label: 'Mulher' },
    { value: 'nao_binario', label: 'Não binário' },
    { value: 'nao_respondeu', label: 'Prefiro não dizer' },
  ];

  return (
    <section>
      {progress >= id + 1 &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Como você identifica o seu gênero?</h1>
            <RadioGroup options={options} value={watch('gender')} onChange={(e: any) => setValue('gender', (e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionGender;
