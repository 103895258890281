import { TextField } from '@mui/material';
import { useState, forwardRef } from 'react';
import { TextFieldProps } from "@mui/material/TextField";
import { Check } from "@mui/icons-material";
import InputAdornment from '@mui/material/InputAdornment';
import { FieldError } from "react-hook-form";
import { HiExclamation } from 'react-icons/hi';
import styles from './index.module.scss';

type MyTextFieldProps = TextFieldProps & {
  name: 'name' | 'email';
  label: string;
  placeholder: string;
  required?: boolean;
  error?: boolean;
  sucess?: boolean;
  msgError?: FieldError,
  width?: string,
  setData: any,
  formData: { name: string, email: string },
  setValue: (e: string, i: string) => void,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputText = forwardRef<HTMLInputElement, MyTextFieldProps>(
  function InputText(data: MyTextFieldProps, ref) {
    const { label, msgError, placeholder, error, sucess, type, formData, setValue, name, setData } = data;
    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = () => {
      setIsFocused(true);
    };

    const handleInputBlur = () => {      
      setIsFocused(false);      
      setValue(name, formData[name]);
      setData({...formData})
    };

    return (
      <div className={`${styles.container} ${(sucess ? styles.sucess : "")} ${(isFocused ? styles.active : "")} ${(error ? styles.error : "")}`
      }>
        <TextField
          label={label}
          className={styles.inputDefault}
          variant="standard"
          fullWidth
          placeholder={placeholder}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {error
                  ? <HiExclamation />
                  : !!sucess
                    ? <Check width={14} height={12} />
                    : ''
                }
              </InputAdornment>
            )
          }}
          error={!!error}
          inputRef={ref}
          onChange={(e) => formData[name] = e.target.value}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          type={type ? type : "text"}
        />
        {msgError && <div className='errorMsg'>*{msgError.message}</div>}
      </div>
    )
  })

InputText.displayName = 'InputText';
export default InputText;
