import React from 'react';
import header from '../images/header.png';

function Header() {
  return (
    <div className="">
      <header id='header' className="App-header">
        <img src={header} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default Header;
