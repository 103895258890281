import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../../components/RadioGroup'
// import CheckBox from '../../components/CheckBox';

function QuestionStopConfraternity({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'sem_tempo', label: 'Não tenho mais tempo' },
    { value: 'sem_interesse', label: 'Perdi o interesse' },
    { value: 'experiencia_ruim', label: 'Não gostei da experiência' },
    { value: 'confraria_acabou', label: 'A confraria acabou' },
  ];
  
  // ALTERADO DE CHECK PARA RADIO
  // const handleCheck = (optionId: string) => {
  //   const arrSelect = watch('stopConfraternity') as [string];

  //   if (arrSelect.includes(optionId)) {
  //     // Desmarcar a opção se já estiver selecionada
  //     setValue('stopConfraternity', arrSelect.filter(id => id !== optionId));
  //   } else {
  //     // Marcar a opção se ainda não foram selecionadas 3 opções
  //     setValue('stopConfraternity', [...arrSelect, optionId]);
  //   }
  // };

  return (
    <section>
      {progress >= id + 1 && watch('brewerConfraternity') === 'ja_fiz' &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Marque o motivo que te fez abandonar a confraria.</h1>
            <RadioGroup options={options} value={watch('stopConfraternity')} onChange={(e: any) => setValue('stopConfraternity', (e.target as HTMLInputElement).value)} />
            {/* <CheckBox options={options} selectedOptions={watch('stopConfraternity')} onChange={(e: any) => handleCheck((e.target as HTMLInputElement).value)} /> */}
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionStopConfraternity;
