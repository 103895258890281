import React from 'react';
import { FormControl } from '@mui/material';
import CheckBox from '../../components/CheckBox';

function QuestionYesEventBeer({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'paga_ingresso_e_cerveja', label: 'Eventos com ingressos mais em conta e paga a cerveja a ser consumida no local' },
    { value: 'paga_ingresso', label: 'Eventos com ingressos mais caros, porém que sejam open bar' },
    { value: 'paga_cerveja', label: 'Sem pagamento de ingressos e paga apenas o que for consumir' },
  ];
  
  const handleCheck = (optionId: string) => {
    const arrSelect = watch('yesEventBeer') as [string];

    if (arrSelect.includes(optionId)) {
      // Desmarcar a opção se já estiver selecionada
      setValue('yesEventBeer', arrSelect.filter(id => id !== optionId));
    } else {
      // Marcar a opção se ainda não foram selecionadas 3 opções
      setValue('yesEventBeer', [...arrSelect, optionId]);
    }
  };

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "especiais_comuns" && watch('beerEvent') === 'sim' &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Que tipo de evento você já foi? (pode marcar mais de um)</h1>
            <CheckBox options={options} selectedOptions={watch('yesEventBeer')} onChange={(e: any) => handleCheck((e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionYesEventBeer;
