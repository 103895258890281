import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../../components/RadioGroup'

function QuestionLightBeer({ index, id, progress, setValue, watch, NextPage }: any) {


  const options = [
    { value: 'bom', label: 'Acho bom' },
    { value: 'ruim', label: 'Acho ruim' },
    { value: 'indiferente', label: 'Não faz diferença pra mim' },
  ];

  return (
    <section>
      {progress >= id + 1 &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Qual a sua opnião sobre cervejas sem álcool?</h1>
            <RadioGroup options={options} value={watch('lightBeer')} onChange={(e: any) => setValue('lightBeer', (e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionLightBeer;
