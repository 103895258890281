import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../../components/RadioGroup'

function QuestionListenPodcast({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'sim', label: 'Sim' },
    { value: 'nao', label: 'Não' },
  ];

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "especiais_comuns" &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Você escuta Podcasts?</h1>
            <RadioGroup options={options} value={watch('listenPodcast')} onChange={(e: any) => setValue('listenPodcast', (e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionListenPodcast;
