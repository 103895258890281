import React from 'react';
import { FormControl } from '@mui/material';
import CheckBox from '../../components/CheckBox';

function QuestionMusicStyle({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'axe', label: 'Axé' },
    { value: 'blues', label: 'Blues' },
    { value: 'classica', label: 'Clássica' },
    { value: 'eletronica', label: 'Eletrônica' },
    { value: 'funk', label: 'Funk' },
    { value: 'jazz', label: 'Jazz' },
    { value: 'metal', label: 'Metal' },
    { value: 'mpb', label: 'MPB' },
    { value: 'gospel', label: 'Gospel' },
    { value: 'pagode', label: 'Pagode' },
    { value: 'pop', label: 'Pop' },
    { value: 'rap', label: 'Rap' },
    { value: 'trap', label: 'Trap' },
    { value: 'reggae', label: 'Reggae' },
    { value: 'rock', label: 'Rock' },
    { value: 'samba', label: 'Samba' },
    { value: 'sertanejo', label: 'Sertanejo' },
  ].sort((a, b) => {
    const nameA = a.label.toUpperCase();
    const nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  
  const handleCheck = (optionId: string) => {
    const arrSelect = watch('musicStyle') as [string];

    if (arrSelect.includes(optionId)) {
      // Desmarcar a opção se já estiver selecionada
      setValue('musicStyle', arrSelect.filter(id => id !== optionId));
    } else if (arrSelect.length < 3) {
      // Marcar a opção se ainda não foram selecionadas 3 opções
      setValue('musicStyle', [...arrSelect, optionId]);
    }
  };

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "especiais_comuns"  &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Quando você bebe cerveja, qual estilo de música você costuma ouvir? (no máximo 3 estilos)</h1>
            <CheckBox options={options} selectedOptions={watch('musicStyle')} onChange={(e: any) => handleCheck((e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionMusicStyle;
