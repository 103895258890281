import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../../components/RadioGroup'

function QuestionBeerSpend({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'ate_100', label: 'Até cem reais' },
    { value: '101_200', label: 'Entre R$ 101,00 e R$ 200,00' },
    { value: '201_400', label: 'Entre R$ 201,00 e R$ 400,00' },
    { value: '401_600', label: 'Entre R$ 401,00 e R$ 600,00' },
    { value: '601_mais', label: 'Mais de R$ 601,00' },
  ];

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "especiais_comuns" &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Quanto você gasta por mês com cervejas?</h1>
            <RadioGroup options={options} value={watch('beerSpend')} onChange={(e: any) => setValue('beerSpend', (e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionBeerSpend;
