import React from 'react';
import { FormControl } from '@mui/material';
import RadioRange from '../../components/RadioRange'

function QuestionMomentConsumBeer({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 1, label: '' },
    { value: 2, label: '' },
    { value: 3, label: '' },
    { value: 4, label: '' },
    { value: 5, label: '' },
  ];

  const fields = [
    { reg: 'momentConsumBeer.sports', label: 'Assistindo a esportes' },
    { reg: 'momentConsumBeer.series', label: 'Assistindo tv, séries ou filmes.' },
    { reg: 'momentConsumBeer.home', label: 'Depois do trabalho em casa' },
    { reg: 'momentConsumBeer.happyHour', label: 'Depois do trabalho em Happy Hour' },
    { reg: 'momentConsumBeer.events', label: 'Eventos ou festas com amigos (festas, bares, boates...)' },
  ];
  
  const handleScroll = (field: string, value: string, key: number) => {    
    setValue(field, value);
    const $section = document.querySelector(`[data-radiorange-scroll="${key+1}"]`);    
    if (!$section) return console.log("section não encontrada");
    $section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "comuns" &&
       <div className='card' key={index} data-scroll={id}>
       <FormControl fullWidth>
         <div>
           <h1 className='mt0 mb0'>Com que frequência de você costuma consumir cervejas nestas ocasiões?</h1>
           <p style={{ fontSize: '16px', color: '#4E4E4E' }}>
             Nas perguntas que seguem abaixo, queremos saber como funciona a sua decisão de consumo em cada ocasião citada.
           </p>
           <div className='flex'>
             <div className='rangeItens range1'>Nunca</div>
             <div className='rangeItens range2'>Poucas vezes</div>
             <div className='rangeItens range3'>As vezes</div>
             <div className='rangeItens range4'>Muitas vezes</div>
             <div className='rangeItens range5'>Sempre</div>
           </div>
         </div>

         <div className='radioRange'>
           {fields.map((field, key) => (
             <div className='mb15' data-radiorange-scroll={key} key={key}>
               <div className='radioRange-label'>
                 <p>{field.label}</p><div className='line'></div>
               </div>
               <RadioRange options={options} value={Number(watch(field.reg))} onChange={(e: any) => handleScroll(field.reg, (e.target as HTMLInputElement).value, key)} />
             </div>
           ))}
         </div>

       </FormControl>
       <footer className='nextPage'>
         <NextPage />
       </footer>
     </div>}
    </section>
  );
}

export default QuestionMomentConsumBeer;
