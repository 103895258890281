import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../../components/RadioGroup'

function QuestionFirstBeer({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'paga_ingresso_e_cerveja', label: 'Pagar um ingresso mais em conta e pagar as cervejas que quiser consumir' },
    { value: 'paga_ingresso', label: 'Pagar apenas o ingresso, mesmo que seja mais caro e consumir a vontade no evento' },
    { value: 'paga_cerveja', label: 'Sem pagamento de ingressos e paga apenas o que for consumir' },
    { value: 'sem_preferencia', label: 'Não tenho preferência' },
  ];

  const selectOption =[...options.filter((option) => watch('yesEventBeer').includes(option.value)), { value: 'sem_preferencia', label: 'Não tenho preferência' }];

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "especiais_comuns" && watch('yesEventBeer').length > 1 &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Qual você preferiu?</h1>
            <RadioGroup options={selectOption} value={watch('favEventBeerType')} onChange={(e: any) => setValue('favEventBeerType', (e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionFirstBeer;
