import React, { useState } from 'react';
import InputText from '../components/InputText/inputText';
import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { HiCheck } from 'react-icons/hi';

function QuestionEmail({ errors, updateEmail, register, watch, setValue }: any) {
  const [data, setData] = useState({
    name: '',
    email: '',
  });

  const handleCheck = (type: string, value: boolean) => {
    setTimeout(() => {
      setValue(type, value);
    }, 100)
  };

  return (
    <section>
      <div className='card mt25'>
        <InputText
          label={'Qual é o seu nome?'}
          placeholder=''
          sucess={!!data.name}
          formData={data}
          setValue={setValue}
          name='name'
          setData={setData}
        />

        <div className='mt17'>
          <InputText
            label={'Qual é o seu email?'}
            placeholder='Ex.: nome@servidor.com'
            sucess={!!data.email}
            error={!!errors.email}
            formData={data}
            msgError={errors?.email}
            setValue={setValue}
            name={'email'}
            setData={setData}
          />
          <input hidden {...register('email', {
            // required: false,
            pattern: {
              value: /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/,
              message: "Email inválido!"
            },
          })} />

          <FormGroup className='mt17 checkboxes'>
            <FormControlLabel onChange={(e) => handleCheck('hopPills', (e.target as HTMLInputElement).checked)} control={<Checkbox />} label="Aceito receber a Hop-Pills, newsletter do Surra de Lúpulo" />
            <FormControlLabel onChange={(e) => handleCheck('prussiaBeer', (e.target as HTMLInputElement).checked)} control={<Checkbox />} label="Aceito receber ofertas e comunicações da Prussia Bier" />
          </FormGroup>
          <div className='buttonEmail' >
            <Button size='large' disabled={!watch('email')} variant='contained' endIcon={<HiCheck />} onClick={() => updateEmail()}> Enviar </Button>
          </div>
        </div>


      </div>
    </section>
  );
}

export default QuestionEmail;
