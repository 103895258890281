import * as React from 'react';

import LinearProgress, { LinearProgressProps } from  '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    
      <Box className='loading'>
        <Typography fontWeight={700} color={'#5D696F'} fontSize={20} marginBottom={'8px'}>
          {`${Math.round(props.value)}%`}
        </Typography>
        <LinearProgress
          variant='determinate'
          {...props} 
        />
      </Box>
  );
}

export default LinearProgressWithLabel;