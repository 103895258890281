import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../../components/RadioGroup'

function QuestionBrewerType({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'cervejeiro_caseiro', label: 'Cervejeiro(a) caseiro(a)' },
    { value: 'cervejeiro_profissional', label: 'Cervejeiro(a) profissional' },
    { value: 'influencer', label: 'Comunicador(a) / Influencer' },
    { value: 'consumidor', label: 'Consumidor(a)' },
    { value: 'dono_negocio', label: 'Dono(a) de negócio cervejeiro (brewpub, pdv, taproom, bar, consultorias...)' },
    { value: 'sommelier_hobbista', label: 'Sommelier / Sommelière hobbista' },
    { value: 'sommelier_profissional', label: 'Sommelier / Sommelière profissional' },
    { value: 'vendedor', label: 'Vendedor(a)' },
  ];

  return (
    <section>
      {progress >= id + 1 &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Como é o seu principal envolvimento com a cerveja? (marque aquele que você mais se identifica)</h1>
            <RadioGroup options={options} value={watch('brewerType')} onChange={(e: any) => setValue('brewerType', (e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionBrewerType;
