import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../../components/RadioGroup'

function QuestionWhatsStopsSpecialBeer({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'acho_caro', label: 'Acho caro' },
    { value: 'nao_gosto', label: 'Não gosto' },
    { value: 'nao_encontro', label: 'Não sei onde encontrar' },
    { value: 'nao_consegue_escolher', label: 'Não sei o que escolher para começar a experimentar' },
  ];
  
  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "comuns" &&
        <div className='card' key={id} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>O que te impede de começar a experimentar outros estilos de cervejas?</h1>
            <RadioGroup options={options} value={watch('whatsStopsSpecialBeer')} onChange={(e: any) => setValue('whatsStopsSpecialBeer', (e.target as HTMLInputElement).value)} />
            {/* <CheckBox options={options} selectedOptions={watch('whatsStopsSpecialBeer')} onChange={(e: any) => handleCheck((e.target as HTMLInputElement).value)} /> */}
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionWhatsStopsSpecialBeer;
