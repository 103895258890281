import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from './index.module.scss';

interface Option {
  value: string;
  label: string;
};

type Props = {
  selectedOptions: string[];
  options: Option[]
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function CheckBox({ options, selectedOptions, onChange }: Props) {
  return (
    <section className={styles.section}>
      {options.map((option, index) => (
        <div key={index} className={selectedOptions.includes(option.value) ? styles.check + " " + styles.container : styles.unCheck + " " + styles.container}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  value={option.value}
                  checked={selectedOptions.includes(option.value)}
                  onChange={onChange}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 18, fill: '#01988C' } }}
                />
              } label={option.label}
            />
          </FormGroup>
        </div>
      ))}
    </section>
  );
}