import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../../components/RadioGroup'

function QuestionZeroBeer({ index, id, progress, setValue, watch, NextPage }: any) {


  const options = [
    { value: 'sim_sem_frequencia', label: 'Sim, mas não consumo com frequência' },
    { value: 'sim_com_frequencia', label: 'Sim e consumo com frequência' },
    { value: 'nao_com_interesse', label: 'Não, mas tenho interesse em experimentar' },
    { value: 'nao_sem_interesse', label: 'Não tenho interesse em experimentar' },
  ];

  return (
    <section>
      {progress >= id + 1 &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Você já experimentou cervejas sem álcool?</h1>
            <RadioGroup options={options} value={watch('zeroBeer')} onChange={(e: any) => setValue('zeroBeer', (e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionZeroBeer;
