import React from 'react';
import { FormControl } from '@mui/material';
import CheckBox from '../../components/CheckBox';

function QuestionWhatOtherItens({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'abridores', label: 'Abridores' },
    { value: 'bolachas', label: 'Bolachas' },
    { value: 'bolsas_termicas', label: 'Bolsas térmicas' },
    { value: 'coolers', label: 'Coolers ou isopores' },
    { value: 'copos_tacas', label: 'Copos ou taças' },
    { value: 'growlers', label: 'Growlers' },
    { value: 'latas', label: 'Latas' },
    { value: 'placas', label: 'Placas' },
    { value: 'rotulos', label: 'Rótulos' },
    { value: 'vestuarios', label: 'Vestuário (bonés, camisetas)' },
  ].sort((a, b) => {
    const nameA = a.label.toUpperCase();
    const nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  
  const handleCheck = (optionId: string) => {
    const arrSelect = watch('whatOtherItens') as [string];

    if (arrSelect.includes(optionId)) {
      // Desmarcar a opção se já estiver selecionada
      setValue('whatOtherItens', arrSelect.filter(id => id !== optionId));
    } else if (arrSelect.length < 3) {
      // Marcar a opção se ainda não foram selecionadas 3 opções
      setValue('whatOtherItens', [...arrSelect, optionId]);
    }
  };

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "especiais_comuns"  && watch('otherBeerItens') === 'sim' &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Marque abaixo até 3 itens que você mais coleciona relacionados à cerveja.</h1>
            <CheckBox options={options} selectedOptions={watch('whatOtherItens')} onChange={(e: any) => handleCheck((e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionWhatOtherItens;
