import React from 'react';
import { FormControl } from '@mui/material';
import RadioGroup from '../../components/RadioGroup'

function questionNoClubSign({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'nada', label: 'Nada me faria mudar de ideia.' },
    { value: 'rotulos_exclusivos', label: 'Acesso a rótulos exclusivos' },
    { value: 'brindes', label: 'Brindes' },
    { value: 'sommelier_proximo', label: 'Contato próximo com o sommelier do clube' },
    { value: 'curadoria', label: 'Curadoria de cervejas' },
    { value: 'desconto_outros_produtos', label: 'Descontos em outros produtos do site' },
    { value: 'preco_distinto', label: 'Faixas de preços distintas' },
    { value: 'valor_frete', label: 'Valor do frete para sua região' },
  ];

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "especiais_comuns" && watch('signClub') === 'nao' &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>O que poderia fazer você mudar de ideia e assinar um clube de cervejas?</h1>
            <RadioGroup options={options} value={watch('noClubSign')} onChange={(e: any) => setValue('noClubSign', (e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default questionNoClubSign;
