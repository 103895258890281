import { useEffect } from 'react';
import { Button, IconButton, Link } from '@mui/material';
import { HiClipboardCopy } from 'react-icons/hi';
import selo1 from '../../images/SeloPesquisa_1.png'
import selo2 from '../../images/SeloPesquisa_2.png'
import selo3 from '../../images/SeloPesquisa_3.png'
import selo4 from '../../images/SeloPesquisa_4.png'
import { Instagram, Twitter, WhatsApp } from '@mui/icons-material';

interface Props {
  totalResearchs: number;
  setAlertOpen: ({ open, msg }: { open: boolean, msg: string }) => void
}

function Email(props: Props) {
  const { totalResearchs, setAlertOpen } = props;
  const faviconTag = document.getElementById("faviconTag") as HTMLLinkElement;;
  const isDark = window.matchMedia("(prefers-color-scheme: dark)");

  const changeFavicon = () => {
    if (isDark.matches) faviconTag.href = "./favicon_dark.png";
    else faviconTag.href = "./favicon_light.png";
  };

  useEffect(() => {
    changeFavicon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const returnSelo = () => {
    switch (totalResearchs) {
      case 1:
        return { selo: selo2, texto: textSelo2, link: 'https://retrato.surradelupulo.com.br/images_email/SeloPesquisa_2.png' }
      case 2:
        return { selo: selo3, texto: textSelo3, link: 'https://retrato.surradelupulo.com.br/images_email/SeloPesquisa_3.png' }
      case 3:
        return { selo: selo4, texto: textSelo4, link: 'https://retrato.surradelupulo.com.br/images_email/SeloPesquisa_4.png' }
      default:
        return { selo: selo1, texto: textSelo1, link: 'https://retrato.surradelupulo.com.br/images_email/SeloPesquisa_1.png' }
    }
  };

  const textSelo1 = (
    <p className='text-selo'>Inclusive, analisando nossos dados, vimos essa é a sua <b>primeira vez conosco.</b> Então de presente te damos esse selo para que você compartilhe nas suas redes sociais.</p>
  );
  const textSelo2 = (
    <p className='text-selo'>Inclusive, analisando nossos dados, vimos essa é a sua <b>segunda vez conosco</b>, é ótimo te ver aqui de novo. Então de presente te damos esse selo para que você compartilhe nas suas redes sociais.</p>
  );
  const textSelo3 = (
    <p className='text-selo'>Inclusive, analisando nossos dados, vimos essa é a sua <b>terceira vez conosco</b>, isso já é um compromisso sério entre nós. Então de presente te damos esse selo para que você compartilhe nas suas redes sociais.</p>
  );
  const textSelo4 = (
    <p className='text-selo'>Inclusive, analisando nossos dados, vimos que você <b>gabaritou e participou de todas as edições.</b> Então de presente te damos esse selo para que você compartilhe nas suas redes sociais.</p>
  );

  const handleShareInstagram = async () => {
    downloadImg();
    window.open('https://www.instagram.com/p/CvZyS20AiIO/?img_index=1', '_blank');
  };

  const handleShareWhatsapp = async () => {
    const metaTag = document.getElementById("og-img") as HTMLLinkElement;
    if (metaTag) {
      metaTag.setAttribute('content', returnSelo().link);
    };
    const texto = `Eu respondi a pesquisa Retrato dos Consumidores de Cervejas. Responda também e ajude a mapear como bebemos a nossa cerveja.
    https://retrato.surradelupulo.com.br`;
    const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(texto)}`;
    window.location.href = whatsappUrl;
  };

  const handleShareTwitter = async () => {
    downloadImg();
    const tweetText = "Eu respondi a pesquisa Retrato dos Consumidores de Cervejas. Responda também e ajude a mapear como bebemos a nossa cerveja."
    const urlToShare = 'https://retrato.surradelupulo.com.br';
    const imageUrl = returnSelo().link;
    // Construindo o URL do Web Intent do Twitter
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${encodeURIComponent(urlToShare)}&media=${encodeURIComponent(imageUrl)}`;

    // Abrindo a janela pop-up para compartilhar no Twitter
    window.open(twitterUrl, '_blank', 'width=600,height=300');
  };

  const downloadImg = () => {
    const imageUrl = returnSelo().link // URL da imagem para download
    const imageName = 'selo.png'; // Nome do arquivo a ser baixado

    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', imageName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Erro no download da imagem:', error);
      });
  };

  const handleCopyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = 'RET23COD200430';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    setAlertOpen({ open: true, msg: 'Texto copiado para o Clipboard: RET23COD200430' });
  };

  return (
    <div className='email'>
        <h1 className=''>Obrigado por ter participado! </h1>
      <div className=''>
      </div>

        <p className='text-result'>
          A coleta de respostas do Retrato dos Consumidores de Cervejas 2023 ocorreu do dia 01/08/2023 a 15/09/2023. 
          No momento estamos analisando os dados para apresentar o resultado.
        </p>
      {/* <Link href='https://loja.prussiabier.com.br/?utm_source=retrato-surra&utm_medium=retrato-surra&utm_campaign=retrato-surra' className='link-store' target="_blank">
        https://loja.prussiabier.com.br/?utm_source=retrato-surra&utm_medium=retrato-surra&utm_campaign=retrato-surra
      </Link> */}
    </div>
  );
}

export default Email;
