import React from 'react';
import { FormControl } from '@mui/material';
import CheckBox from '../../components/CheckBox';

function QuestionHowEADBeer({ index, id, progress, setValue, watch, NextPage }: any) {

  const options = [
    { value: 'introdutorio', label: 'Curso introdutório' },
    { value: 'especializacao_estilos', label: 'Especialização em estilos' },
    { value: 'harmonizacao', label: 'Harmonização' },
    { value: 'marketing', label: 'Marketing para o mercado de cerveja' },
    { value: 'mestre_cervejeiro', label: 'Mestre cervejeiro' },
    { value: 'off_flavors', label: 'Off Flavors' },
    { value: 'producao_caseira', label: 'Produção caseira de cerveja' },
    { value: 'profissional_sommelier', label: 'Profissional de Sommelier de Cerveja' },
    { value: 'tecnologia_cervejeira', label: 'Tecnologia cervejeira' },
    { value: 'workshops', label: 'Workshops' },
    { value: 'nao_faria', label: 'Não faria nenhum curso EAD' },
    { value: 'outros', label: 'Outros' },
  ];
  
  const handleCheck = (optionId: string) => {
    const arrSelect = watch('howEadBeer') as [string];

    if (arrSelect.includes(optionId)) {
      // Desmarcar a opção se já estiver selecionada
      setValue('howEadBeer', arrSelect.filter(id => id !== optionId));
    } else if (optionId === 'nao_faria') {
      // Marcar a opção não desmarca qualquer outra
      setValue('howEadBeer', [optionId]);
    } else if (arrSelect.includes('nao_faria')) {
      // Se tiver marcado a opção "não" marcar qualquer outra a desmarca
      setValue('howEadBeer', [optionId]);
    } else if (arrSelect.length < 3) {
      // Marcar a opção se ainda não foram selecionadas 3 opções
      setValue('howEadBeer', [...arrSelect, optionId]);
    }
  };

  return (
    <section>
      {progress >= id + 1 && watch('beerType') === "especiais_comuns" &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Marque até 3 cursos que você faria no formato EAD (educação à distância).</h1>
            <CheckBox options={options} selectedOptions={watch('howEadBeer')} onChange={(e: any) => handleCheck((e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionHowEADBeer;
