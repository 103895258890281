import React from 'react';
import { FormControl } from '@mui/material';
import CheckBox from '../components/CheckBox';

function QuestionBeersBrands({ index, id, progress, setValue, watch, NextPage }: any) {


  const options = [
    { value: 'nenhuma_dessas', label: 'Não bebo essas cervejas' },
    { value: '1500', label: '1500' },
    { value: 'amstel', label: 'Amstel' },
    { value: 'antarctica', label: 'Antárctica' },
    { value: 'bavaria', label: 'Bavária' },
    { value: 'becks', label: 'Becks' },
    { value: 'berrio', label: 'Berrió' },
    { value: 'bohemia', label: 'Bohemia' },
    { value: 'brahma', label: 'Brahma' },
    { value: 'brahma_duplo_malte', label: 'Brahma Duplo Malte' },
    { value: 'budweiser', label: 'Budweiser' },
    { value: 'cacildis', label: 'Cacildis' },
    { value: 'corona', label: 'Corona' },
    { value: 'cristal', label: 'Cristal' },
    { value: 'devassa', label: 'Devassa' },
    { value: 'eisenbahn', label: 'Eisenbahn' },
    { value: 'esmera', label: 'Esmera' },
    { value: 'estrella_galicia', label: 'Estrella Galicia' },
    { value: 'glacial', label: 'Glacial' },    
    { value: 'heineken', label: 'Heineken' },
    { value: 'imperio', label: 'Império' },
    { value: 'itaipava', label: 'Itaipava' },
    { value: 'kaiser', label: 'Kaiser' },
    { value: 'lokal', label: 'Lokal' },
    { value: 'magnifica', label: 'Magnífica' },
    { value: 'original', label: 'Original' },
    { value: 'petra', label: 'Petra' },
    { value: 'polar', label: 'Polar' },
    { value: 'serra_malte', label: 'Serra Malte' },
    { value: 'skin', label: 'Skin' },
    { value: 'skol', label: 'Skol' },
    { value: 'sol', label: 'Sol' },
    { value: 'spaten', label: 'Spaten' },
    { value: 'stella_artois', label: 'Stella Artois' },
    { value: 'sul_americana', label: 'SulAmericana' },    
  ];

  const handleCheck = (optionId: string) => {
    const arrSelect = watch('beersBrands') as [string];

    if (arrSelect.includes(optionId)) {
      // Desmarcar a opção se já estiver selecionada
      setValue('beersBrands', arrSelect.filter(id => id !== optionId));
    } else if (optionId === 'nenhuma_dessas') {
      // Marcar a opção não desmarca qualquer outra
      setValue('beersBrands', [optionId]);
    } else if (arrSelect.includes('nenhuma_dessas')) {
      // Se tiver marcado a opção "não" marcar qualquer outra a desmarca
      setValue('beersBrands', [optionId]);
    } else if (arrSelect.length < 3) {
      // Marcar a opção se ainda não foram selecionadas 3 opções
      setValue('beersBrands', [...arrSelect, optionId]);
    }
  };

  return (
    <section>
      {progress >= id + 1 &&
        <div className='card' key={index} data-scroll={id}>
          <FormControl fullWidth>
            <h1 className='mt0'>Quais as cervejas comuns que você mais consome? (marque até 3)</h1>
            <CheckBox options={options} selectedOptions={watch('beersBrands')} onChange={(e: any) => handleCheck((e.target as HTMLInputElement).value)} />
          </FormControl>
          <footer className='nextPage'>
            <NextPage />
          </footer>
        </div>}
    </section>
  );
}

export default QuestionBeersBrands;
